tab-selector {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;

  & tab-item {
    display: block;

    border: solid 2px var(--color-teal-d);
    padding: var(--space-x-small) var(--space-small);
    border-radius: 1em;

    & > * {
      font-size: calc(0.4em + 1vw);
      display: block;
      color: var(--color-teal-d);
      padding-top: 0.2em;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    &[am-state~="active"] {
      background: var(--color-teal-d);

      & > * {
        color: white;
      }
    }
  }
}