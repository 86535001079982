@font-face {
    font-family: 'Farro';
    src: local('Farro Regular'), local('Farro-Regular'),
        url("../fonts/farro/Farro-Regular.woff2") format('woff2'),
        url("../fonts/farro/Farro-Regular.woff") format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Farro';
    src: local('Farro Bold'), local('Farro-Bold'),
        url("../fonts/farro/Farro-Bold.woff2") format('woff2'),
        url("../fonts/farro/Farro-Bold.woff") format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Farro';
    src: local('Farro Light'), local('Farro-Light'),
        url("../fonts/farro/Farro-Light.woff2") format('woff2'),
        url("../fonts/farro/Farro-Light.woff") format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Farro';
    src: local('Farro Medium'), local('Farro-Medium'),
        url("../fonts/farro/Farro-Medium.woff2") format('woff2'),
        url("../fonts/farro/Farro-Medium.woff") format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}