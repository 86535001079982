html {
  scroll-behavior: smooth;
}

main {
  padding-top: calc(var(--header-height));
  margin: 0 var(--space-medium);
}

section.hero {
  & figure {
    position: relative;
    max-width: 90ch;
    margin: auto;

    & img {
      border-radius: 1em;
      width: calc(22ch + 16vw);
      height: 26em;
      object-fit: cover;
      filter: contrast(120%);
      animation: 0.5s ease-out 0s 1 slideInUp;
    }

    & h1 {
      position: absolute;
      top: 5%;
      left: 30%;
      border-radius: 1em;
      padding: var(--space-small);
      background: linear-gradient(225deg, hsla(154, 75%, 87%, 0.6) 70%, hsla(154, 75%, 92%, 0.9));
      font-size: 270%;
      animation: 0.5s ease-in-out 0s 1 slideInLeft;

      @media (max-width: 40em) {
        font-size: var(--type-x-large);
      }
    }

    @media (max-width: 30em) {
      max-width: 95%;
      & h1 {
        left: 20%;
      }
    }
  }
}

section.about {
  padding-top: var(--space-large);

  & figure img {
    display: block;
    margin: auto;
    width: calc(10rem + 20vw);
    border-radius: 50%;
  }

  & section-content {
    display: block;
    max-width: 70ch;
    margin: auto;
  }

  & article {
    border: 2px solid var(--color-indigo-d);
    padding: var(--space-small) var(--space-medium);
    border-radius: 1em;
  }
}


section.skills {
  padding: var(--space-large) 0;

  & button-group {
    justify-content: space-evenly;
    flex-wrap: wrap;

    & img {
      height: 3em;
      max-width: 100%;
    }

    & > * {
      flex: 0 0 auto;
      margin-top: var(--space-small);
    }
  }
}

section.contact {
  & a {
    font-size: var(--type-xx-large);
    font-weight: 600;
  }

  @media(max-width: 60em) {
    font-size: var(--type-small);
    & a {
      font-size: var(--type-x-large);
    }
  }

  @media(max-width: 40em) {
    & a {
      font-size: var(--type-large);
    }
  }
}

.no-js [data-aos] {
  opacity: 1 !important;
  transform: none !important;
}

anchor-link {
  display: block;
  padding-top: var(--header-height);
  margin-top: calc(-1 * var(--header-height))
}