[am-margin~="sm"] {
  margin: var(--space-small);
}

[am-margin~="md"] {
  margin: var(--space-medium);
}

[am-margin~="lg"] {
  margin: var(--space-large);
}

[am-margin~="vertical-sm"] {
  margin-top: var(--space-small);
  margin-bottom: var(--space-small);
}

[am-margin~="vertical-md"] {
  margin-top: var(--space-medium);
  margin-bottom: var(--space-medium);
}

[am-margin~="vertical-lg"] {
  margin-top: var(--space-large);
  margin-bottom: var(--space-large);
}

[am-margin~="horizonal-sm"] {
  margin-left: var(--space-small);
  margin-right: var(--space-small);
}

[am-margin~="horizonal-md"] {
  margin-left: var(--space-medium);
  margin-right: var(--space-medium);
}

[am-margin~="horizonal-lg"] {
  margin-left: var(--space-large);
  margin-right: var(--space-large);
}

[am-margin~="top-sm"] {
  margin-top: var(--space-small);
}

[am-margin~="top-md"] {
  margin-top: var(--space-medium);
}

[am-margin~="top-lg"] {
  margin-top: var(--space-large);
}

[am-margin~="left-sm"] {
  margin-left: var(--space-small);
}

[am-margin~="left-md"] {
  margin-left: var(--space-medium);
}

[am-margin~="left-lg"] {
  margin-left: var(--space-large);
}

[am-margin~="right-sm"] {
  margin-right: var(--space-small);
}

[am-margin~="right-md"] {
  margin-right: var(--space-medium);
}

[am-margin~="right-lg"] {
  margin-right: var(--space-large);
}

[am-margin~="bottom-sm"] {
  margin-bottom: var(--space-small);
}

[am-margin~="bottom-md"] {
  margin-bottom: var(--space-medium);
}

[am-margin~="bottom-lg"] {
  margin-bottom: var(--space-large);
}