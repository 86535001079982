article.talk {
  margin: var(--space-medium) var(--space-x-small);
  background: var(--color-indigo-tint);
  padding: var(--space-medium);
  border-radius: 1em;
  max-width: unset;

  & iframe {
    width: 70vw;
    height: 42vw;
  }
}