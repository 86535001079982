[am-button] {
  display: flex;
  align-items: center;
  background: var(--button-color);
  text-align: center;
  border-radius: 1em;
  padding: var(--space-x-small) var(--space-medium);
  padding-top: 0.4em;
  line-height: normal;
  color: white;
  line-height: 1.5;
  text-decoration: none;

  &:hover {
    background: var(--button-hover-color);
  }

  & svg {
    width: 0.9em;
    fill: white;
    margin-left: var(--space-small);
    padding-bottom: 0.15em;
  }
}

hr {
  width: 100%;
  height: 2px;
  border: none;
  background: var(--color-indigo-md);
  margin-bottom: var(--space-large);
}

hr[am-color~=teal] {
  background: var(--color-teal-d);
}

button-group {
  display: flex;
  align-items: center;
  justify-content: center;

  & > * ~ * {
    padding-left: var(--space-small);
  }
}

picture-card {
  --border-radius: 1em;

  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  border: 2px solid var(--color-indigo-d);
  padding-bottom: var(--space-medium);

  & > *:not(figure) {
    padding: 0 var(--space-medium);
  }

  & figure {
    position: relative;

    & h1 {
      display: flex;
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      height: 100%;
      text-align: center;
      justify-content: center;
      align-items: center;
      font-weight: 300;
      font-size: 250%;
      line-height: 1.4;
      color: var(--color-indigo-tint);
      text-shadow: 1px 1px 0.5px var(--color-indigo-d), -1px -1px 0.5px var(--color-indigo-d);
    }

    & img {
      border-radius: 0.9em 0.9em 0 0;
      width: 100%;
      height: 15em;
      object-fit: cover;
      filter: brightness(50%) contrast(120%);
    }
  }

  & article {
    flex: 1 0 auto;
  }

  & footer {
    display: block;
    margin-top: var(--space-small);
    text-align: right;
    align-self: flex-end;

    & a {
      text-decoration: none;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 3px;
        text-underline-offset: 5px;
      }
    }

    & svg {
      height: 1em;
      width: 1.5em;
      stroke: var(--link-color);
      margin-top: -5px;
    }
  }
}

svg.icon {
  width: 2em;
  height: 2em;
  fill: var(--link-color);

  &:hover {
    fill: var(--color-red-d);
  }
}

svg.icon--large {
  width: 4em;
  height: 4em;
}