body {
  font-family: var(--font-sans);
  line-height: 1.6;
}

h1 {
  font-size: var(--type-xxx-large);
  font-weight: 100;
  line-height: 1.3;
}

h2 {
  font-size: var(--type-xx-large);
  color: var(--color-indigo-md);
  line-height: 1.3;
}

h3 {
  font-size: var(--type-x-large);
  color: var(--color-indigo-md);
  line-height: 1.3;
}

h4 {
  font-size: var(--type-large);
  line-height: 1.3;
}

h5 {
  font-size: var(--type-medium);
  font-weight: 800;
  line-height: 1.3;
}

h6 {
  font-size: var(--type-small);
  font-weight: 900;
  line-height: 1.3;
}

@media (max-width: 60em) {
  h1 {
    font-size: var(--type-xx-large);
  }

  h2 {
    font-size: var(--type-x-large);
  }

  h3 {
    font-size: var(--type-large);
  }

  h4 {
    font-size: var(--type-medium);
  }
}

code {
  font-family: monospace;
}

p {
  margin: var(--space-medium) 0;
  font-weight: 300;
}

a {
  color: var(--link-color);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

mark {
  border-radius: 1.4em 0 2em 0;
  background: transparent;
  background-image: linear-gradient(-100deg, hsla(48, 94%, 68%, 0.3), hsla(48, 94%, 68%, 0.7) 95%, hsla(48, 94%, 68%, 0.1));
}

article {
  display: block;
  max-width: 70ch;
  margin: auto;
}

[am-text~="center"] {
  text-align: center;
}

[am-text~="xxx-small"] {
  font-size: var(--type-xxx-small);
}

[am-text~="xx-small"] {
  font-size: var(--type-xx-small);
}

[am-text~="x-small"] {
  font-size: var(--type-x-small);
}

[am-text~="small"] {
  font-size: var(--type-small);
}

[am-text~="medium"] {
  font-size: var(--type-medium);
}

[am-text~="large"] {
  font-size: var(--type-large);
}

[am-text~="x-large"] {
  font-size: var(--type-x-large);
}

[am-text~="xx-large"] {
  font-size: var(--type-xx-large);
}

[am-text~="xxx-large"] {
  font-size: var(--type-xxx-large);
}