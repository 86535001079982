testimonial-container {
  display: block;
  max-width: 50ch;
  margin: auto;

  & blockquote {
    font-size: var(--type-small);

    &:before {
      content: '❝ ';
      color: var(--color-indigo-d);
    }

    &:after {
      content: ' ❞';
      color: var(--color-indigo-d);
    }
  }

  & quote-source {
    display: block;
    font-size: var(--type-small);
    text-align: right;
    margin-top: var(--space-small);
  }
}
