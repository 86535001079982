section.services > section-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30em, 1fr));
  margin: var(--space-large) var(--space-small);
  grid-gap: var(--space-x-large);
  justify-items: center;

  & > * {
    max-width: 40em;
  }

  @media (max-width: 40em) {
    grid-template-columns: repeat(auto-fit, 100%);
  }
}