article.project {
  background: var(--color-indigo-tint);
  padding: var(--space-large);
  border-radius: 1em;
  margin: var(--space-large);
  max-width: unset;

  & article-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(20em + 5vw), 1fr));
    align-items: center;
    justify-items: center;
    grid-gap: 2em;

    & img {
      max-width: 100%;
      height: calc(20em + 1vw);
      object-fit: contain;
    }
  }

  & testimonial-container {
    background: white;
    border-radius: 1em;
    padding: var(--space-medium);
  }

  @media (max-width: 60em) {
    margin: var(--space-large) var(--space-medium);
    padding: var(--space-large) var(--space-medium);
  }

  @media (max-width: 40em) {
    margin: var(--space-large) 0;

    & article-content {
      grid-template-columns: repeat(auto-fit, minmax(calc(10em + 5vw), 1fr));
    }
  }
}