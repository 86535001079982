.service-details {
  & figure {
    position: relative;

    & h1 {
      display: flex;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      text-align: center;
      justify-content: center;
      align-items: center;
      color: var(--color-indigo-tint);
      text-shadow: 1px 1px 0.5px var(--color-indigo-d), -1px -1px 0.5px var(--color-indigo-d);
      font-weight: 400;
    }

    & img {
      width: 100vw;
      margin-left: calc(var(--space-medium) * -1);
      height: 15em;
      object-fit: cover;
      filter: brightness(50%) contrast(120%);
    }
  }

  & article > img {
    display: block;
    margin: auto;
    width: 10em;
    border-radius: 0.8em;
    box-shadow: 1px 1px 10px 1px var(--color-gray-ml);
  }
}