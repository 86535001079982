@font-face {
    font-family: 'Biryani';
    src: local('Biryani ExtraLight'), local('Biryani-ExtraLight'),
        url("../fonts/biryani/Biryani-ExtraLight.woff2") format('woff2'),
        url("../fonts/biryani/Biryani-ExtraLight.woff") format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Biryani';
    src: local('Biryani SemiBold'), local('Biryani-SemiBold'),
        url("../fonts/biryani/Biryani-SemiBold.woff2") format('woff2'),
        url("../fonts/biryani/Biryani-SemiBold.woff") format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Biryani';
    src: local('Biryani Light'), local('Biryani-Light'),
        url("../fonts/biryani/Biryani-Light.woff2") format('woff2'),
        url("../fonts/biryani/Biryani-Light.woff") format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Biryani';
    src: local('Biryani ExtraBold'), local('Biryani-ExtraBold'),
        url("../fonts/biryani/Biryani-ExtraBold.woff2") format('woff2'),
        url("../fonts/biryani/Biryani-ExtraBold.woff") format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Biryani';
    src: local('Biryani Bold'), local('Biryani-Bold'),
        url("../fonts/biryani/Biryani-Bold.woff2") format('woff2'),
        url("../fonts/biryani/Biryani-Bold.woff") format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Biryani';
    src: local('Biryani Black'), local('Biryani-Black'),
        url("../fonts/biryani/Biryani-Black.woff2") format('woff2'),
        url("../fonts/biryani/Biryani-Black.woff") format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Biryani';
    src: local('Biryani Regular'), local('Biryani-Regular'),
        url("../fonts/biryani/Biryani-Regular.woff2") format('woff2'),
        url("../fonts/biryani/Biryani-Regular.woff") format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
