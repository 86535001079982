:root {
  --color-indigo-d: hsl(234, 62%, 26%);
  --color-indigo-md: hsl(232, 51%, 36%);
  --color-indigo-tint: hsl(221, 68%, 93%);

  --color-yellow-d: hsl(15, 86%, 30%);
  --color-red-d: hsl(348, 94%, 20%);

  --color-teal-d: hsl(170, 97%, 15%);
  --color-teal-m: hsl(162, 63%, 41%);
  --color-teal-tint: hsl(152, 68%, 96%);

  --color-purple-md: hsl(262, 69%, 31%);

  --color-red-d: hsl(350, 94%, 28%);
  --color-red-tint: hsl(360, 100%, 95%);

  --color-gray-ml: hsl(211, 13%, 65%);
  --color-gray-tint: hsl(216, 33%, 97%);

  --link-color: var(--color-yellow-d);
  --button-color: var(--color-purple-md);
  --button-hover-color: var(--color-red-d);
}