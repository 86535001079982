body > header {
  display: flex;
  align-items: center;
  padding: 0 var(--space-medium);
  position: fixed;
  width: calc(100vw - calc(var(--space-medium) * 2));
  height: var(--header-height);
  background: hsla(262, 90%, 98%, 0.98);
  z-index: 99;

  & nav {
    text-align: right;
    flex: 1 0 auto;

    & a {
      color: var(--color-red-d);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 3px;
        text-underline-offset: 5px;
      }

      &[am-state~="active"] {
        color: var(--color-purple-md);
        text-decoration: underline;
        text-decoration-thickness: 3px;
        text-underline-offset: 5px;
        pointer-events: none;
      }

      @media(max-width: 40em) {
        font-size: var(--type-small);
      }
    }

    & a ~ a {
      padding-left: var(--space-medium);

      @media(max-width: 40em) {
        padding-left: 1.5vw;
      }
    }
  }


  @media(max-width: 40em) {
    flex-direction: column;
    align-items: flex-start;

    & > * {
      padding-top: var(--space-small);
    }
  }
}

.logo > * {
  font-family: var(--font-logo);
  color: black;
  text-decoration: none;
  font-size: var(--type-x-large);
  font-weight: 800;

  &:hover {
    text-decoration: none;
  }
}